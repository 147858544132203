.boxSeats {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 18px 7px;
}

.seat {
  height: 26px;
  width: 26px;
  background-color: #c3cfd9;
  border: 1px solid #808f9d;
  border-radius: 50px;
  font-size: 11px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.boxModelSeats {
  margin-top: 16px;

  display: flex;
  justify-content: space-around;
}

.seatModel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seatModel p {
  margin-top: 10px;
  font-size: 13px;
  color: #4e5a65;
}

.select {
  background-color: #8dd7cf;
  border: 1px solid #1aae9e;
}

.available {
  background-color: #c3cfd9;
  border: 1px solid #7b8b99;
}

.unavailable {
  background-color: #fbe192;
  border: 1px solid #f7c52b;
}

.boxInputs {
  margin: 41px 0 57px 0;
}

.numberSeat {
  color: #293845;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-style: italic;
}

.dataInput p {
  margin: 10px 0 5px 0;
  font-size: 18px;
  color: #293845;
}

.dataInput input {
  height: 51px;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  padding-left: 18px;
}

.dataInput input::placeholder {
  margin-left: 18px;
  font-style: italic;
  font-size: 18px;
}

.reserve {
  display: flex;
  justify-content: center;
}

.reserve button {
  height: 42px;
  width: 225px;
  background: #e8833a;
  border-style: none;
  border-radius: 3px;

  font-size: 18px;
  color: white;
}

.seatPage {
  padding: 0 24px 120px 24px;
}
