.sucessPage {
  padding: 0 29px 0 29px;
}

.sucessPage .title p {
  color: #247a6b;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.sucessPage button {
  height: 42px;
  width: 225px;
  background: #e8833a;
  border-style: none;
  border-radius: 3px;

  font-size: 18px;
  color: white;
}

.sucessPage .littleTitle {
  margin-top: 20px;
  color: #293845;
  font-weight: 700;
  font-size: 24px;
}

.sucessPage .data {
  margin-top: 10px;
  color: #293845;
  font-size: 22px;
  line-height: 26px;
}

.sucessPage .buttonHome {
  margin-top: 67px;

  display: flex;
  justify-content: center;
}
