.loadingPage {
  background: white;
  background-size: cover;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-3cz2ujhhk8t {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-3cz2ujhhk8t div {
  box-sizing: border-box !important;
}
.ldio-3cz2ujhhk8t > div {
  position: absolute;
  width: 56.17px;
  height: 56.17px;
  top: 40.415000000000006px;
  left: 40.415000000000006px;
  border-radius: 50%;
  border: 6.8500000000000005px solid #000;
  border-color: #85a2b6 transparent #85a2b6 transparent;
  animation: ldio-3cz2ujhhk8t 2.6315789473684212s linear infinite;
}
.ldio-3cz2ujhhk8t > div:nth-child(2) {
  border-color: transparent;
}
.ldio-3cz2ujhhk8t > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-3cz2ujhhk8t > div:nth-child(2) div:before,
.ldio-3cz2ujhhk8t > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.8500000000000005px;
  height: 6.8500000000000005px;
  top: -6.8500000000000005px;
  left: 17.810000000000002px;
  background: #85a2b6;
  border-radius: 50%;
  box-shadow: 0 49.32000000000001px 0 0 #85a2b6;
}
.ldio-3cz2ujhhk8t > div:nth-child(2) div:after {
  left: -6.8500000000000005px;
  top: 17.810000000000002px;
  box-shadow: 49.32000000000001px 0 0 0 #85a2b6;
}
.loadingio-spinner-dual-ring-76xx1frdxbr {
  width: 137px;
  height: 137px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-3cz2ujhhk8t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-3cz2ujhhk8t div {
  box-sizing: content-box;
}
