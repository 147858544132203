.title {
  height: 110px;
  margin-top: 67px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sessionsFilm {
  padding-left: 24px;
  padding-bottom: 120px;
}

.daySession p {
  color: #293845;
  font-size: 20px;
}

.schedules {
  margin-top: 22px;

  display: flex;
  flex-wrap: wrap;
}

.schedules button {
  margin-right: 8px;
  margin-bottom: 6px;
  height: 43px;
  width: 82px;

  color: #ffffff;
  font-size: 18px;

  background: #e8833a;
  border: none;
  border-radius: 3px;
}

.session {
  margin-bottom: 26px;
}

footer {
  height: 117px;
  width: 100%;
  padding: 14px;
  border: 1px solid #9eadba;
  background-color: #dfe6ed;

  display: flex;

  position: fixed;
  bottom: 0;
  z-index: 1;
}

.boxImage {
  height: 89px;
  width: 64px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.littleImage img {
  height: 72px;
  width: 48px;
}

.informationFilm {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.informationFilm p {
  font-size: 26px;
  font-weight: 400;
  color: #293845;
}
